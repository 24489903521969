import React from 'react';
import Layout from '../components/Common/Layout';
import Seo from '../components/Common/Seo';

export default (props: any) => (
  <Layout hash={props?.location?.hash}>
    <Seo
      title="software with purpose"
      description="craftable software specialises in systems that require quality, reliability and scalability in a highly transactional environment for complex software ecosystems."
    />
    Cookies
  </Layout>
);
